const SERVER_URL_PREFIX = 'https://crm.connectandengagehc.com/public/Api/';
export const environment = {
  production: false,
  apiUrl: 'https://tele.connectandengagehc.com/dashboardv/',
  chaturl: 'https://tele.connectandengagehc.com/',
  chat_url: 'https://dulcmpc09chle.cloudfront.net/',
  smssend_url:'https://dxwhkfqmck.execute-api.us-east-1.amazonaws.com/prod',
  accessKeyId: '<<accessKeyId>>',
  secretAccessKey: '<<secretAccessKey>>',
  REST_URL: SERVER_URL_PREFIX + 'V8/custom/',
  SERVER_URL_PREFIX: SERVER_URL_PREFIX,
  agentListurl:"https://rl51r6e437.execute-api.us-east-1.amazonaws.com/dev",
  agentActivity:"https://stcn7yane8.execute-api.us-east-1.amazonaws.com/dev",
  quelist:"https://6ojnrcn2mh.execute-api.us-east-1.amazonaws.com/prod",
  downloadreport:"https://m30b1e3ic2.execute-api.us-east-1.amazonaws.com/prod",
  indivisualmetric:"https://0jyrk01tf8.execute-api.us-east-1.amazonaws.com/dev",
};
