import { Component, OnInit, ViewChild } from '@angular/core';
import { PatientService } from '../../../src/app/service/patient.service';
import { Patient } from '../models/Patient';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableDataSource, MatDialog, MatPaginator, MatInput, MatSort } from '@angular/material';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
declare const $: any;
@Component({
	selector: 'app-supervisorleads',
	templateUrl: './supervisorleads.component.html',
	styleUrls: ['./supervisorleads.component.css']
})
export class SupervisorleadsComponent implements OnInit {
	public caseNumber1: string = '';
	public orderName: string = '';
	// public formCaseDetails = new FormGroup({});
	// public formOrderCreate = new FormGroup({});
	public authResponse: any;
	public accessToken: any;
	// public patient:Patient[] = [] as Patient[];
	case: any;
	orderCreate: any;
	userList: any;
	alluserlist = [];
	showDetailsDiv: boolean = false;
	records_per_page = 25;
	start_count = 0;
	totalRecords;
	pagecount = 25;
	showFilter = false;
	selectedDisposition: any = "";
	selectedStatus = '';
	selectedCampaign = '';
	pref_comm_channel: any = "";
	Scriptvalue: any = "";
	userName = '';
	campaignList: any = [];
	sel_contacts: any = [];
	select_useroption = '';
	DispositionList: any = [];
	pref_comm_channelList: any = [];
	StatusList:any = [];
	script_list: any = [];
	public dataSource = new MatTableDataSource();
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
	total_calls: any = 0;
	calls_completed: any = 0;
	show_status = false;
	session_id: any = '';
	campaign_id: any = '';
	select_All = false;
	all_usersList: any = [];
	loadspinner: any;
	inputVisible: any;
	inputVisible_All: any;
	inputMessage: any;
	inputMessage_All: any;
	displayedColumns: string[] = ["firstName", "lastName", "phoneNumber", "dob", "email", "status", "disposition", "modified_date"];
	temp_userList = [{ "id": "1", "select_user": false, "firstName": "user1", "lastName": "ln", "phoneNumber": "+12407740002", "email": "user1@gmail.com", "status": "Active", "disposition_value": "Follow-up Required", "disposition_key": "Follow_Up_Required", "modified_date": "06/13/2024 11:18", "editDisposition": false },
	{
		"id": "2", "select_user": false, "firstName": "user2", "lastName": "ln", "phoneNumber": "+18332702517", "email": "user1@gmail.com", "status": "Active", "disposition_value": "Follow-up Required",
		"disposition_key": "Follow_Up_Required", "modified_date": "06/13/2024 11:18", "editDisposition": false
	},
	{
		"id": "3", "select_user": false, "firstName": "user3", "lastName": "ln", "phoneNumber": "+12407740002", "email": "user1@gmail.com", "status": "Active", "disposition_value": "Follow-up Required",
		"disposition_key": "Follow_Up_Required", "modified_date": "06/13/2024 11:18", "editDisposition": false
	},
	{
		"id": "4", "select_user": false, "firstName": "user4", "lastName": "ln", "phoneNumber": "+18332702517", "email": "user1@gmail.com", "status": "Active", "disposition_value": "Follow-up Required",
		"disposition_key": "Follow_Up_Required", "modified_date": "06/13/2024 11:18", "editDisposition": false
	},
	{
		"id": "5", "select_user": false, "firstName": "user5", "lastName": "ln", "phoneNumber": "+12407740002", "email": "user1@gmail.com", "status": "Active", "disposition_value": "Follow-up Required",
		"disposition_key": "Follow_Up_Required", "modified_date": "06/13/2024 11:18", "editDisposition": false
	}]
	temp_campaignList = [{
		"key": "Campaign_1",
		"value": "Campaign 1"
	},
	{
		"key": "Campaign_2",
		"value": "Campaign 2"
	},
	{
		"key": "Campaign_3",
		"value": "Campaign 3"
	}]
	constructor(private patientService: PatientService, private formBuilder: FormBuilder, private router: Router) {
		this.accessToken = '';
	}

	ngOnInit(): void {
		this.session_id = localStorage.getItem("unique_sessionId");
		this.login();
		let userInfo = JSON.parse(localStorage.getItem('currentUser'));
		this.userName = userInfo ? userInfo.userName : 'myousuff';
	}

	public login() {
		this.loadspinner = true;
		localStorage.setItem("access_token", '');
		localStorage.setItem("refresh_token", '');
		let grantType = 'client_credentials';
		let client_id = 'cea98ac6-4698-3a89-ec90-66ebff42e231';
		let client_secret = 'CzJwyznmgYJftSXx3n'
		this.patientService.getToken(grantType, client_id, client_secret).subscribe((resp: any) => {

			localStorage.setItem("access_token", resp.access_token);
			this.getCampaignsList();
			return "Bearer " + JSON.stringify(localStorage.getItem('access_token'));
		});
	}

	getCampaignsList() {

		this.accessToken = localStorage.getItem('access_token');
		this.patientService.getCampaignsList(this.accessToken, this.start_count, this.records_per_page, this.selectedStatus, this.selectedDisposition, this.userName).subscribe((data) => {
			this.campaignList = data['campaign_list'];
			this.getUserList();
		},
			(err: any) => {
				this.getUserList();
			}
		);
	}

	getDispositionList() {
		this.loadspinner = true;
		this.accessToken = localStorage.getItem('access_token');
		this.patientService.getApi_DispositionList(this.accessToken).subscribe((data) => {
			const updatedData = data['disposition_list'].filter(item => item.key !== "")
			this.DispositionList = updatedData;
			this.pref_comm_channelList = data['pref_comm_channel_list'];
			this.script_list = data['script_list'];
			const updatedData_status = data['lead_status_list'].filter(item => item.key !== "")
			this.StatusList = updatedData_status
			this.loadspinner = false;
		},
			(err: any) => {
			}
		);
	}

	getUserList() {

		this.show_status = false;
		this.accessToken = localStorage.getItem('access_token');
		this.showDetailsDiv = false;
		// this.userList = this.temp_userList;
		// this.all_usersList = this.temp_userList;
		// this.dataSource.data = this.temp_userList;
		// this.totalRecords = 5
		this.loadspinner = true;
		this.patientService.getUserList(this.accessToken, this.start_count, this.records_per_page, this.selectedStatus, this.selectedDisposition, this.userName, this.selectedCampaign, this.pref_comm_channel).subscribe((data) => {

			this.userList = data['data'];
			this.all_usersList = data['data'];
			this.dataSource.data = data['data'];
			this.totalRecords = data['rowCount'];
			this.getDispositionList();
			this.loadspinner = false;
		});
	}

	pageChanged() {
		this.dataSource.data = null;
		var pagevalue = this.paginator.pageIndex;
		this.start_count = pagevalue * this.records_per_page;
		this.getUserList();
	}

	makecall(number, userName) {
		let arndata = localStorage.getItem("ARN_value");
		// document.getElementById('loading').style.display = "block";
		this.loadspinner = true;
		this.patientService.makecallApi(number, userName, arndata).subscribe((data: any) => {
			// setTimeout(() => {
			// 	document.getElementById('loading').style.display = "none";
			// }, 2000)
			this.loadspinner = false;

		}, (err: any) => {
			// document.getElementById('loading').style.display = "none";
			this.loadspinner = false;
			swal('Oops...', 'Something went wrong, Please try again!', 'error')
		})
	}
	filter() {
		this.showFilter = !this.showFilter;
		this.getUserList();
	}

	reset() {
		this.showFilter = !this.showFilter;
		this.calls_completed = 0;
		this.totalRecords = 0;
		this.selectedDisposition = '';
		this.selectedStatus = '';
		this.selectedCampaign = '';
		this.pref_comm_channel = "";
		this.Scriptvalue = "";
		this.getUserList();
	}

	search() {
		this.calls_completed = 0;
		this.totalRecords = 0;
		this.showFilter = !this.showFilter;
		this.getUserList();
	}

	selected_Disposition(value: any) {

		this.selectedDisposition = value;
		console.log(this.selectedDisposition)
	}

	selected_Status(value: any) {

		this.selectedStatus = value;
		console.log(this.selectedStatus)
	}

	selected_Campaign(value: any) {
		this.selectedCampaign = value;
		this.campaign_id = value;

	}

	selected_prefcommchannel(value: any) {
		this.pref_comm_channel = value;
	}

	selected_Script(value: any) {
		this.Scriptvalue = value;
		// this.router.navigate(['/supervisorleads-scripts']);
		const scriptId = value; // The script ID you want to pass
		const encodedKeyScriptId = btoa('script_id');
		const encodedScriptId = btoa(scriptId); // Base64 encoding


		const route = "#/supervisorleads-scripts";
		const baseUrl = window.location.origin;
		const fullUrl = `${baseUrl}/${route}?${encodedKeyScriptId}=${encodeURIComponent(encodedScriptId)}`;

		// Open in a new window
		const popup = window.open(
			fullUrl,
			'_blank',
			'width=800,height=600,scrollbars=yes,resizable=yes'
		);

		if (popup) {
			popup.focus();
		}


	}



	MessageiconClick(rowIndex: number) {
		if (this.inputVisible === rowIndex) {
			this.inputVisible = null;  // Hide the input box if clicked again
		} else {
			this.inputVisible = rowIndex // Show the input box for the clicked row
		}
	}

	closePopup() {
		this.inputVisible = null; // Close the popup
		this.inputVisible_All = null;
	}

	submitMessage(phonenumber) {
		
		if (this.inputMessage) {
			this.inputVisible = null;
			let body = {
				"customer_numbers": [phonenumber],
				"message": this.inputMessage,
				"variant": "0",
			}


			this.loadspinner = true;
			this.patientService.sendmeaage_Api(body).subscribe((data: any) => {
				this.loadspinner = false;
				this.inputMessage = '';
				this.inputVisible = null;
				swal({
					title: 'Success!',
					text: '',
					type: 'success',  // For SweetAlert2 v7, 'type' is used instead of 'icon'
					confirmButtonText: 'OK',  // Custom label for the confirm button
					width: '450px'
				  });

			}, (err: any) => {
				console.log(err)
				swal('Oops...', 'Something went wrong, Please try again!', 'error')
				this.loadspinner = false;
			})
		}
	}

	MessageiconClick_All(data) {
		if (this.inputVisible_All === data) {
			this.inputVisible_All = null;
		}
		else {
			this.inputVisible_All = data;
		}
	}

	submitMessageALL(phonenumber) {
		
		if (this.inputMessage_All) {
			this.inputVisible_All = null;
			let user_data: any = [];

			for (var i = 0; i < this.sel_contacts.length; i++) {
				let t: any;
				user_data.push(this.sel_contacts[i].phoneNumber)
			}

			let body = {
				"customer_numbers": user_data,
				"message": this.inputMessage_All,
				"variant": "0",
			}


			this.loadspinner = true;
			this.patientService.sendmeaage_Api(body).subscribe((data: any) => {
				this.loadspinner = false;
				this.inputMessage_All = '';
				this.inputVisible_All = null;
				swal({
					title: 'Success!',
					text: '',
					type: 'success',  // For SweetAlert2 v7, 'type' is used instead of 'icon'
					confirmButtonText: 'OK', // Custom label for the confirm button
					customClass: 'small-swal',
					width: '450px'
				  });

			}, (err: any) => {
				console.log(err)
				swal('Oops...', 'Something went wrong, Please try again!', 'error')
				this.loadspinner = false;
			})
		}
	}

	selectuser_option(value: any) {
		this.select_useroption = value;
		if (this.select_useroption == 'selectall') {
			this.userList.forEach(element => {
				element.select_user = true
			});
			this.sel_contacts = this.userList;
		} else if (this.select_useroption == 'deselectall') {
			this.userList.forEach(element => {
				element.select_user = false
			});
			this.sel_contacts = []
		}

	}

	selectAll_user(value: any) {

		//this.userList = this.all_usersList;
		if (value == true) {
			this.select_useroption = 'selectall'
		} else {
			this.select_useroption = 'deselectall'
		}

		if (this.select_useroption == 'selectall') {
			this.userList.forEach(element => {
				element.select_user = true
			});
			this.sel_contacts = [];
			this.sel_contacts = this.all_usersList;
		} else if (this.select_useroption == 'deselectall') {
			this.userList.forEach(element => {
				element.select_user = false
			});
			this.sel_contacts = []
		}

	}


	make_outboundcall() {
		let arndata = localStorage.getItem("ARN_value");
		let user_data: any = [];

		for (var i = 0; i < this.sel_contacts.length; i++) {
			let t: any;
			t = { "name": this.sel_contacts[i].firstName, "phone": this.sel_contacts[i].phoneNumber }
			user_data.push(t)
		}
		let body = {
			"dataset": user_data,
			// "agentarn": "arn:aws:connect:us-east-1:482256725001:instance/a655c061-4cd0-4e60-95c0-cfa5a3bc8a6c/agent/a6032749-97e3-44ab-bd0b-488f5a37e0e5",
			"agentarn": arndata,
			"campaign_id": this.campaign_id,
			"total_count": this.totalRecords,
			"session_id": this.session_id
		}
		this.loadspinner = true;
		this.patientService.makeoutboundcallApi(body).subscribe((data: any) => {
			this.sel_contacts = [];
			this.userList.forEach(element => {
				element.select_user = false
			});
			//this.event_ID = data["EventId"];
			this.get_callStatus();
			setInterval(() => {
				this.get_callStatus();
			}, 5000)

			this.loadspinner = false;

		})
	}


	refresh() {
		this.get_callStatus();
	}

	get_callStatus() {
		this.show_status = true;
		this.accessToken = localStorage.getItem('access_token');
		this.patientService.get_callStatusAPI(this.accessToken, this.session_id, this.campaign_id).subscribe((data) => {
			console.log(data)
			this.loadspinner = false;
			this.total_calls = data['total'];
			this.calls_completed = data['completed'];
		},
			(err: any) => {
				console.log(err)
			}
		);
	}

	select_user(data, check_flag) {
		if (this.select_useroption == 'selectall' || this.select_useroption == 'deselectall') {
			this.select_useroption = '';
			this.select_All = false;
		}
		if (check_flag) {
			this.sel_contacts.push(data);
		} else {
			for (var i = 0; i < this.sel_contacts.length; i++) {
				if (this.sel_contacts[i].select_user == false) {
					this.sel_contacts.splice(i, 1)
				}
			}
		}
	}


	edit_disposition(data) {
		data.editDisposition = true;
	}

	dynamic_DispositionUpdate(value: any, data) {
		data.disposition_key = value;

	}

	save_disposition(data) {
		this.loadspinner = true;
		this.accessToken = localStorage.getItem('access_token');
		let body = {
			"lead_id": data.id,
			"disposition_key": data.disposition_key
		}

		this.patientService.updateDisposition(this.accessToken, body).subscribe((res: any) => {
			data.editDisposition = false;
			this.loadspinner = false;
			this.calls_completed = 0;
			this.totalRecords = 0;
			this.getUserList();

		})
	}

	edit_status(data){
		data.edit_statuskey = true;
	}

	dynamic_statusUpdate(value: any, data){
		data.status_key = value;

	}

	save_status(data){
		this.loadspinner = true;
		this.accessToken = localStorage.getItem('access_token');
		let body = {
			"lead_id": data.id,
			"status_key": data.status_key
		}

		this.patientService.updateDisposition(this.accessToken, body).subscribe((res: any) => {
			data.edit_statuskey = false;
			this.loadspinner = false;
			this.calls_completed = 0;
			this.totalRecords = 0;
			this.getUserList();

		})

	}

	replacePhone(phone: any) {
		return phone.replace("+1", "")
	}

	recenttabledata_refresh(){
		this.getUserList();
	}
}
