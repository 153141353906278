import { Component, OnInit, ViewChild } from '@angular/core';
import { PatientService } from '../../../src/app/service/patient.service';
import { Patient } from '../models/Patient';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { FormsModule,ReactiveFormsModule }   from '@angular/forms';
import { MatTableDataSource, MatDialog, MatPaginator, MatInput, MatSort } from '@angular/material';
import swal from 'sweetalert2';
declare const $: any;
@Component({
  selector: 'app-supervisorleads',
  templateUrl: './supervisorleads.component.html',
  styleUrls: ['./supervisorleads.component.css']
})
export class SupervisorleadsComponent implements OnInit {
    public caseNumber1:string = '';
	public orderName:string = '';
	// public formCaseDetails = new FormGroup({});
	// public formOrderCreate = new FormGroup({});
	public authResponse:any;
	public accessToken:any;
	// public patient:Patient[] = [] as Patient[];
	case:any;
	orderCreate:any;
	userList:any;
	alluserlist = [];
	showDetailsDiv:boolean = false;
	records_per_page=25;
	start_count=0;
	totalRecords;
	pagecount=25;
	showFilter=false;
	selectedDisposition='';
	selectedStatus='';
	selectedCampaign='';
	userName='';
	campaignList:any=[];
	sel_contacts:any=[];
	select_useroption='';
  public dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  total_calls:any=0;
  calls_completed:any=0;
  show_status = false;
  session_id:any='';
  campaign_id:any='';
  select_All=false;
  all_usersList:any=[];
  displayedColumns: string[] = ["firstName", "lastName", "phoneNumber", "dob",  "email", "status", "disposition", "modified_date"];
temp_userList = [{"id":"1", "select_user":false, "firstName":"user1", "lastName":"ln", "phoneNumber":"+12407740002", "email":"user1@gmail.com", "status":"Active",  "disposition_value":"Follow-up Required", "disposition_key":"Follow_Up_Required", "modified_date":"06/13/2024 11:18", "editDisposition":false},
				 {"id":"2", "select_user":false, "firstName":"user2", "lastName":"ln", "phoneNumber":"+18332702517", "email":"user1@gmail.com", "status":"Active", "disposition_value":"Follow-up Required",
				 "disposition_key":"Follow_Up_Required", "modified_date":"06/13/2024 11:18", "editDisposition":false},
				 {"id":"3", "select_user":false, "firstName":"user3", "lastName":"ln", "phoneNumber":"+12407740002", "email":"user1@gmail.com", "status":"Active", "disposition_value":"Follow-up Required", 
					"disposition_key":"Follow_Up_Required", "modified_date":"06/13/2024 11:18", "editDisposition":false},
				 {"id":"4", "select_user":false, "firstName":"user4", "lastName":"ln", "phoneNumber":"+18332702517", "email":"user1@gmail.com", "status":"Active", "disposition_value":"Follow-up Required", 
					"disposition_key":"Follow_Up_Required", "modified_date":"06/13/2024 11:18", "editDisposition":false},
				 {"id":"5", "select_user":false, "firstName":"user5", "lastName":"ln", "phoneNumber":"+12407740002", "email":"user1@gmail.com", "status":"Active", "disposition_value":"Follow-up Required", 
					"disposition_key":"Follow_Up_Required", "modified_date":"06/13/2024 11:18", "editDisposition":false}]
temp_campaignList =[ {
	"key": "Campaign_1",
	"value": "Campaign 1"
},
{
	"key": "Campaign_2",
	"value": "Campaign 2"
},
{
	"key": "Campaign_3",
	"value": "Campaign 3"
}]
	constructor(private patientService: PatientService, private formBuilder: FormBuilder) {
		this.accessToken = '';
	}

	ngOnInit(): void {
		 this.session_id = localStorage.getItem("unique_sessionId");
		this.login();
	let userInfo = JSON.parse(localStorage.getItem('currentUser'));
	this.userName = userInfo ? userInfo.userName : '';
	}

	public login(){
		
		localStorage.setItem("access_token", '');
		localStorage.setItem("refresh_token", '');
		let grantType='client_credentials';
		let client_id='c21f57d6-6559-e250-13f8-654b3203bc53';
		let client_secret='63dNb0lAGFyqKnuNXQGJ'
		this.patientService.getToken(grantType, client_id, client_secret).subscribe((resp:any) => {
			
			localStorage.setItem("access_token", resp.access_token);
			this.getCampaignsList();
			return "Bearer "+JSON.stringify(localStorage.getItem('access_token'));
		});
	}

	getCampaignsList(){
		this.accessToken = localStorage.getItem('access_token');
		this.patientService.getCampaignsList(this.accessToken, this.start_count, this.records_per_page, this.selectedStatus, this.selectedDisposition, this.userName).subscribe((data) => {
			this.campaignList = data['campaign_list'];
			this.getUserList();
		},
		(err:any)=>{
			this.getUserList();
		}
	);
	}

	getUserList(){
		this.show_status = false;
		this.accessToken = localStorage.getItem('access_token');
		this.showDetailsDiv = false;
		// this.userList = this.temp_userList;
		// this.all_usersList = this.temp_userList;
		// this.dataSource.data = this.temp_userList;
		// this.totalRecords = 5
		
		this.patientService.getUserList(this.accessToken, this.start_count, this.records_per_page, this.selectedStatus, this.selectedDisposition, this.userName, this.selectedCampaign).subscribe((data) => {
			this.userList = data['data'];
			this.all_usersList = data['data'];
			this.dataSource.data = data['data'];
			this.totalRecords = data['rowCount'];
		});
	}

	pageChanged() {
		this.dataSource.data = null;
		var pagevalue = this.paginator.pageIndex;
		this.start_count = pagevalue * this.records_per_page;
		this.getUserList();   
	  }
	  
	  makecall(number, userName){
		document.getElementById('loading').style.display = "block";
		this.patientService.makecallApi(number, userName).subscribe((data:any)=>{
			console.log(data);
			setTimeout(()=>{
				document.getElementById('loading').style.display = "none";
			}, 2000)
			
		}, (err:any)=>{
			document.getElementById('loading').style.display = "none";
			swal('Oops...', 'Something went wrong, Please try again!', 'error')
		})
	  }
	  filter(){
		this.showFilter = !this.showFilter ;
		this.getUserList();
	 }

	 reset(){
		this.showFilter = !this.showFilter ;
		this.calls_completed = 0;
		this.totalRecords = 0;
		this.selectedDisposition = '';
		this.selectedStatus = '';
		this.selectedCampaign = '';
		this.getUserList();
	 }

	 search(){
		this.calls_completed = 0;
		this.totalRecords = 0;
		this.showFilter = !this.showFilter ;
		this.getUserList();
	 }

	 selected_Disposition(value: any){
	   
	   this.selectedDisposition = value;
	   console.log(this.selectedDisposition)
	 }

	 selected_Status(value: any){
	   
	   this.selectedStatus = value;
	   console.log(this.selectedStatus)
	 }

	 selected_Campaign(value: any){
		this.selectedCampaign = value;
		this.campaign_id = value;
		
	 }

	 selectuser_option(value: any){
		this.select_useroption = value;
		if(this.select_useroption == 'selectall'){
		this.userList.forEach(element => {
		element.select_user = true
		});
		this.sel_contacts = this.userList;
		}else if(this.select_useroption == 'deselectall'){
			this.userList.forEach(element => {
				element.select_user = false
		});
		this.sel_contacts = []
		}
		
	 }

	 selectAll_user(value:any){
		
		//this.userList = this.all_usersList;
		if(value == true){
			this.select_useroption = 'selectall'	
		}else{
			this.select_useroption = 'deselectall'
		}

		if(this.select_useroption == 'selectall'){
			this.userList.forEach(element => {
			element.select_user = true
			});
			this.sel_contacts = [];
			this.sel_contacts = this.all_usersList;
			}else if(this.select_useroption == 'deselectall'){
				this.userList.forEach(element => {
					element.select_user = false
			});
			this.sel_contacts = []
			}
		
	  }


	 make_outboundcall(){
		let user_data:any=[];
		
		for(var i=0 ; i< this.sel_contacts.length; i++){
			let t:any;
			t = { "name": this.sel_contacts[i].firstName, "phone":this.sel_contacts[i].phoneNumber}
			user_data.push(t)
		}
		let body = {
			 "dataset":user_data,
			"agentarn":"arn:aws:connect:us-east-1:482256725001:instance/a655c061-4cd0-4e60-95c0-cfa5a3bc8a6c/agent/a6032749-97e3-44ab-bd0b-488f5a37e0e5",
			"campaign_id":this.campaign_id,
			"total_count":this.totalRecords,
			"session_id":this.session_id
		}
		this.patientService.makeoutboundcallApi(body).subscribe((data:any)=>{
			this.sel_contacts = [];
			this.userList.forEach(element => {
				element.select_user = false
		    });
			//this.event_ID = data["EventId"];
			this.get_callStatus();
			setInterval(()=>{
				this.get_callStatus();
			}, 5000)
			
		})
	  }


	  refresh(){
		this.get_callStatus();
	  }

	  get_callStatus(){
		this.show_status = true;
		this.accessToken = localStorage.getItem('access_token');
		
		this.patientService.get_callStatusAPI(this.accessToken, this.session_id, this.campaign_id).subscribe((data) => {
			console.log(data)
			this.total_calls = data['total'];
			this.calls_completed = data['completed'];
		},
		(err:any)=>{
			console.log(err)
		}
	);
	  }

	  select_user(data, check_flag){
		if(this.select_useroption == 'selectall' || this.select_useroption == 'deselectall'){
			this.select_useroption = '';
			this.select_All = false;
		}
		 if(check_flag){
		this.sel_contacts.push(data);
		 }else{
			for(var i=0; i<this.sel_contacts.length; i++){
				if(this.sel_contacts[i].select_user == false){
					this.sel_contacts.splice(i, 1)
				}
			}
		 }
	  }

	 
	  edit_disposition(data){
		data.editDisposition = true;
	  }

	  save_disposition(data){
		this.accessToken = localStorage.getItem('access_token');
		let body = {
				"lead_id": data.id,
    			"disposition_key": data.disposition_key
				}
				
		this.patientService.updateDisposition(this.accessToken, body).subscribe((res:any)=>{
			data.editDisposition = false;
			this.calls_completed = 0;
		this.totalRecords = 0;
		this.getUserList();
			
		})
	  }

	  dynamic_DispositionUpdate(value: any, data){
		data.disposition_key = value;
		
	  }

	  replacePhone(phone:any){
		return phone.replace("+1", "")
	  }
}
