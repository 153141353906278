import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';

declare const $: any;

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
    nextTab: string;
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [{
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'dashboard',
    nextTab: ''
},
{
    path: '/supervisorleads',
    title: 'View Patients/Members',
    type: 'link',
    icontype: 'wysiwyg',
   nextTab: ''
    // nextTab: 'http://crm.dev.stellaridealabs.com/permit/'
},
{
    path: '/ticket',
    title: 'CRM',
    type: 'link',
    icontype: 'wysiwyg',
    nextTab: 'https://crm.connectandengagehc.com/public/#/home'
},
// {
//     path: '/agent-historicalmetrics',
//     title: 'Historical Metrics',
//     type: 'sub',
//     icontype: 'access_time',
//     collapse: 'historicalmetrics',
//     children: [
//         { path: 'callanalytics', title: 'Call Analytics', ab: 'CA' },
//         { path: 'agentanalytics', title: 'Agent Analytics', ab: 'AA' },
//         { path: 'queueanalytics', title: 'Queue Analytics', ab: 'QA' },
//     ]
// },
// {
//     path: '/agent-advancedanalytics',
//     title: 'Advanced Analytics',
//     type: 'sub',
//     icontype: 'timeline',
//     collapse: 'advancedanalytics',
//     children: [
//         { path: 'trendanalytics', title: 'Trend Analytics', ab: 'TA' },
//         { path: 'sentimentanalytics', title: 'Sentiment Analytics', ab: 'SA' },
//         { path: 'linguisticanalytics', title: 'Linguistic Analytics', ab: 'LA' },
//     ]
// },


// {
//     path: '/agent-admin',
//     title: 'Admin',
//     type: 'link',
//     icontype: 'account_circle'
// },
// {
//     path: '/agent-configure',
//     title: 'Configure',
//     type: 'link',
//     icontype: 'settings'
// },


];
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    ps: any;
    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            this.ps = new PerfectScrollbar(elemSidebar);
        }
    }
    updatePS(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }
}
