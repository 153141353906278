import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthGuard } from '../auth/auth.guard';

interface ItemsResponseObj { }
interface ItemsResponse { }

@Injectable({
  providedIn: 'root'
})



export class AdvancedanalyticsService {

  startDate_lingustic;
  endDate_lingustic;
  page_size;

  constructor(private httpClient: HttpClient) { }


  wordclouddata(startDate, endDate): Observable<ItemsResponse> {
    return this.httpClient.get<ItemsResponse>(environment.apiUrl + 'alliancectr/trend-cloud?startDate=' + startDate + '&endDate=' + endDate + '');
  }

  wordCloudTextData(wordText: any, pageIndex: any, startDate, endDate): Observable<ItemsResponse> {
    return this.httpClient.get<ItemsResponse>(environment.apiUrl + 'alliancectr/find-word?word=' + wordText + '&startDate=' + startDate + '&endDate=' + endDate + '&pageNo=' + pageIndex + '&pageSize=10&sortBy=connectedToDate');
  }

  wordCloudTextData_search_filter(wordText: any, pageIndex: any, startDate, endDate, searchObj): Observable<ItemsResponse> {
    return this.httpClient.post<ItemsResponse>(environment.apiUrl + 'alliancectr/trend-search?word=' + wordText + '&startDate=' + startDate + '&endDate=' + endDate + '&pageNo=' + pageIndex + '&pageSize=10&sortBy=connectedToDate', searchObj);

  }

  wordCloudTextData_sorting(wordText: any, pageIndex: any, startDate, endDate, sortfield, sortstatus, searchobj){

    
  //console.log(sortfield, "---- in service page");

  return this.httpClient.post<ItemsResponse>(environment.apiUrl + 'alliancectr/sort-word?word=' + wordText + '&startDate=' + startDate + '&endDate=' + endDate + '&pageNo=' + pageIndex + '&pageSize=10&sortColumn='+ sortfield +'&sortDesc='+ sortstatus, searchobj);

  }

  
  wordCloudToneAnalyzerData(contactId: any): Observable<ItemsResponse> {
    return this.httpClient.get<ItemsResponse>(environment.apiUrl + 'linguistic/sentence-tone?contactId=' + contactId + '&sortBy=ContactId');
  }

  lingusticdate(date1, date2) {
    this.startDate_lingustic = date1;
    this.endDate_lingustic = date2;
  }

  filter_pagesize(count) {
    this.page_size = count;
  }


  linguisticAnalytics(toneName: any, toneScore: any, pageIndex: any, pagesize: any) {
    return this.httpClient.get<ItemsResponse>(environment.apiUrl + 'linguistic/analytics?toneName=' + toneName + '&score=' + toneScore + '&startDate=' + this.startDate_lingustic + '&endDate=' + this.endDate_lingustic + '&pageNo=' + pageIndex + '&pageSize=' + pagesize + '&sortBy=connectedToDate');

  }


linguisticAnalytics_sorting(toneName: any, toneScore: any, pageIndex: any, pagesize: any,  sortfield, sortstatus, searchobj){


  return this.httpClient.post<ItemsResponse>(environment.apiUrl + 'linguistic/sort-analytics?toneName=' + toneName + '&score=' + toneScore + '&startDate=' + this.startDate_lingustic + '&endDate=' + this.endDate_lingustic + '&pageNo=' + pageIndex + '&pageSize=' + pagesize + '&sortColumn='+ sortfield +'&sortDesc='+ sortstatus, searchobj);

}


  linuisticToneAnalytics(contactId: any) {
    return this.httpClient.get<ItemsResponse>(environment.apiUrl + 'linguistic/sentence-tone?contactId=' + contactId + '&sortBy=ContactId');
  }


  lingustic_search_filter(toneName: any, toneScore: any, pageIndex: any, pagesize: any, searchObj) {

    return this.httpClient.post(environment.apiUrl + 'linguistic/search?toneName=' + toneName + '&score=' + toneScore + '&startDate=' + this.startDate_lingustic + '&endDate=' + this.endDate_lingustic + '&pageNo=' + pageIndex + '&pageSize=' + pagesize + '&sortBy=connectedToDate', searchObj);
  }

  sentiment_bardata(startDate, endDate) {
    return this.httpClient.get<any>(environment.apiUrl + 'sentimentanalytics/classifier-names?startDate=' + startDate + '&endDate=' + endDate + '');

  }

  sentiment_linedata(startDate, endDate) {
    return this.httpClient.get<any>(environment.apiUrl + 'sentimentanalytics/tones?startDate=' + startDate + '&endDate=' + endDate + '');
  }
}