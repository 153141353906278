import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { CommonService } from '../service/common.service';
declare const $: any;
declare var connect: any;
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  myCPP: any;
  static appendvalue: any
  static agentname: any;
  static username: any;
  static duration: any;
  static userstatus: any;
  static agentStates: any;
  static agentnew: any;
  timeString;
  static loginusername: any;
  static browserRefresh = false;
  static currentStaus;
  static callimage;
  static queuelist;

  constructor(private router: Router, private loggeduserService: CommonService, private httpClient: HttpClient) { }

  handleContactEnded() {
    document.getElementById("incomingcall").style.display = "none";
    document.getElementById("nocall").style.display = "block";
    document.getElementById("customerName").innerHTML = "";
  }

  login() {
    this.myCPP = this.myCPP || {};
    var ccpUrl = "https://connectandengage.my.connect.aws/ccp-v2";
    AuthGuard.appendvalue = document.getElementById('containerDiv');
    connect.core.initCCP(AuthGuard.appendvalue, {
      ccpUrl: ccpUrl,
      loginPopup: true,
      softphone: {
        allowFramedSoftphone: true
      }
    });
    
    connect.contact((contact) => {
      if ((contact.getStatus().type == "connecting")||(contact.getStatus().type == 'incoming') || (contact.getStatus().type == 'pending')){
        document.getElementById("incomingcall").style.display = "block";
        document.getElementById("nocall").style.display = "none";
        $("#myModal").modal();
      }

      var attributeMap = contact.getAttributes();
      var name = JSON.stringify(attributeMap["fullName"]["value"]);
      name=name.replace(/["]/g, '');
 
      contact.onIncoming(function(){
       contact.accept();
        document.getElementById("customerName").innerHTML ="Calling "+ name;
      });
      contact.onConnected(function(){
        document.getElementById("customerName").innerHTML ="Connected with " + name ;
      });
      contact.onConnecting(function(){
        document.getElementById("customerName").innerHTML ="Connecting with "+ name;
      })
      contact.onEnded(this.handleContactEnded);
    });

    
    connect.agent(this.subscribeToAgentEvents);

    connect.agent((agent) => {
     
      AuthGuard.agentStates = agent.getAgentStates();
      AuthGuard.agentnew = agent;
      AuthGuard.currentStaus = agent.getStatus().name;
      AuthGuard.queuelist = agent.getRoutingProfile().queues;

      var userObj = {
        userName: agent.getConfiguration().username,
        status: agent.getStatus().name,
        duration: agent.getStatusDuration(),
        routingProfile: agent.getRoutingProfile().name,
        queueName: agent.getRoutingProfile().defaultOutboundQueue.name,
        hierarchyName: " ",
        state: "1"
      }


      localStorage.setItem('currentUserccpframe', JSON.stringify(AuthGuard.appendvalue.innerHTML));
      localStorage.setItem('currentUser', JSON.stringify(userObj));

      this.loggeduserService.loggeduserdata(userObj);
      agent.onRefresh(() => {
        localStorage.setItem('username', JSON.stringify(agent.getName()));
        $("#loginusername").text(agent.getName());
        $("#userstaus").text(agent.getStatus().name);
        if (AuthGuard.currentStaus != agent.getStatus().name) {

          var userObj_change = {
            userName: agent.getConfiguration().username,
            status: agent.getStatus().name,
            duration: agent.getStatusDuration(),
            routingProfile: agent.getRoutingProfile().name,
            queueName: agent.getRoutingProfile().defaultOutboundQueue.name,
            hierarchyName: " ",
            state: "1"
          }
          this.loggeduserService.loggeduserdata(userObj_change);
          AuthGuard.currentStaus = agent.getStatus().name;
        }

        var userObj_duration = {
          userName: agent.getConfiguration().username,
          status: agent.getStatus().name,
          duration: agent.getStatusDuration(),
          state: "1"
        }
        this.loggeduserService.loggedUserUpdateDuration(userObj_duration);

        setInterval(function () {
          if (agent.getStatus().type == "offline") {
            $("#userduration").text("");
          } else {
            var time = agent.getStatusDuration();
            var ms = time % 1000;
            time = (time - ms) / 1000;
            var secs = time % 60;
            time = (time - secs) / 60;
            var mins = time % 60;
            var hrs = (time - mins) / 60;
            this.timeString = hrs + 'h :' + mins + 'm :' + secs + 's - ';
            $("#userduration").text(this.timeString);

          }
        }, 10)

      })

    })

    connect.core.getEventBus().subscribe(connect.EventType.TERMINATED, function () {
      localStorage.removeItem('loginuser');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('currentUserccpframe');
      localStorage.removeItem('username');
      localStorage.removeItem('iframer_loader_session');
      document.getElementById('yogadashboard').style.display = "none";
      window.location.href = 'https://tele.connectandengagehc.com/';
    });

    // 
  }


  subscribeToAgentEvents(agent) {
    
    if (agent.getName()) {
      AuthGuard.loginusername = agent.getConfiguration().username;
      var queue = agent.getRoutingProfile().queues;
      var ContactId;
      let found = queue.filter(function (item) {
        if (item.queueId.split("/").indexOf("agent") >= 0) {
          return true;
        }
        return false;
      });
      if (found.length > 0) {
        var res = found[0].queueId.split("/");
        ContactId = res[4];
      }

      if (ContactId) {
        console.log("......contact ID....", ContactId);
        
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.open("GET", "https://8zakrt4fi6.execute-api.us-east-1.amazonaws.com/dev/describeUser?userId=" + ContactId, false); // false for synchronous request
        xmlHttp.send(null);
        var userInfo = JSON.parse(xmlHttp.responseText);
        console.log(".....", userInfo);
        AuthGuard.agentname = userInfo.Name;
        localStorage.removeItem("unique_sessionId");
        const d = new Date();
        let time:any = d.getTime();
        let UniqueId = AuthGuard.agentname+time;
        localStorage.setItem("unique_sessionId", UniqueId)
        localStorage.setItem('loginuser', JSON.stringify(AuthGuard.agentname));
      }
    }
  }



  available(agent) {
    var offlineState = agent.getAgentStates().filter(function (state) {
      return state.type === connect.AgentStateType.ROUTABLE;
    })[0];
    agent.setState(offlineState, {
      success: function () {
        //   console.log('agent sucess');
      },
      failure: function () {
        //   console.log('agent failure');
      }
    });

  }

  goAvailable() {
    connect.agent(this.available);
  }

  goOffline() {
    connect.agent(this.offline);
  }

  offline(agent) {
    var offlineState = agent.getAgentStates().filter(function (state) {
      return state.type === connect.AgentStateType.OFFLINE;
    })[0];
    agent.setState(offlineState, {
      success: function () {
        // console.log('agent sucess');
      },
      failure: function () {
        //  console.log('agent failure');
      }
    });
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    this.login();
    return new Promise((resolve) => {
      
      if (typeof AuthGuard.agentname === 'undefined') {
       
        console.log("..............", AuthGuard.agentname);
        // AuthGuard.agentname = "Admin"
        // AuthGuard.duration = "77271990"
        var myflag = setInterval(function () {
          if (typeof AuthGuard.agentname !== 'undefined') {
            clearInterval(myflag);
            resolve(true);
          }
        }, 10000);
      }
    })
    return false;
  }

}